<template>
  <v-card>
    <v-card-title>
      {{ "Emergency Items" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        {{ "Add New" }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span>
          <v-btn depressed text color="primary" @click="read(item.id)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon left dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogAdd" persistent max-width="720px">
      <v-card flat>
        <v-btn icon @click="refresh()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-card flat class="pa-15">
          <emergency-item-add></emergency-item-add>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRead" persistent max-width="720px">
      <v-card flat>
        <v-btn icon @click="refresh()">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-card flat class="pa-15">
          <emergency-item-read :emergencyItems="emergencyItems"></emergency-item-read>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete, mdiClose } from "@mdi/js";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import Request from "@/core/api/request";
import EmergencyItemAdd from "./EmergencyItemAdd.vue";
import EmergencyItemRead from "./EmergencyItemRead.vue";

export default {
  name: "EmergencyItemBrowse",
  directives: { permission },
  components: { EmergencyItemRead, EmergencyItemAdd },

  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      emergencyItems: {},
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Name", value: "name", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
        mdiClose,
      },
      dialogAdd: false,
      dialogRead: false,
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.EMERGENCY_ITEMS__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;
        return;
      }
      data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data;
      this.loading = false;
    },
    refresh() {
      this.dialogAdd = false;
      this.dialogRead = false;
      this.initialize();
    },
    read(param) {
      this.$router.push({ name: "EmergencyItem-Read", params: { id: param } });
    },
    add() {
      this.dialogAdd = true;
    },
    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },
    async destroy(param) {
      await Request.destroy(`${this.$IYA.URI.EMERGENCY_ITEMS__DELETE}`, { id: param }).then(response => {
        if (response.status !== this.$IYA.HTTP.OK) {
          this.$swal(`Failed`, `Failed to remove record!`, `error`);

          return;
        }
        this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
        this.initialize();
      });
    },
  },
};
</script>
